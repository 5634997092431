import { IconBellRing } from '@troon/icons';
import { DialogTrigger } from '@troon/ui';
import dayjs from 'dayjs';
import { TeeTimeAlertDialog } from '../../course/[facilityId]/(sub)/reserve-tee-time/components/tee-time-alert';

type Props = {
	players: number | undefined;
	date: string;
	startAt: number;
	endAt: number;
};

export function SetAlert(props: Props) {
	return (
		<TeeTimeAlertDialog
			selectedDay={dayjs(props.date).toDate()}
			selectedPlayers={props.players}
			selectedTime={[props.startAt, props.endAt]}
		>
			<DialogTrigger appearance="transparent" class="h-full grow-0 flex-col px-3 py-2 normal-case">
				<IconBellRing class="text-2xl" />
				<span>
					Set alert <span class="sr-only">for tee times on this day</span>
				</span>
			</DialogTrigger>
		</TeeTimeAlertDialog>
	);
}
