import { createMemo } from 'solid-js';
import { createDollarFormatter } from '../../../modules/number-formatting';
import type { TeeTimeFragment } from '../../../graphql';

/**
 * Extracted out so suspense works correctly
 */
export function PriceRange(props: { teeTimes?: Record<string, Array<TeeTimeFragment>> }) {
	const dollarFormatter = createDollarFormatter('en');
	const feeString = createMemo(() => {
		const teeTimes = Object.values(props.teeTimes ?? {}).flat();
		if (!teeTimes.length) {
			return null;
		}
		const [min, max] = teeTimes.reduce(
			(memo, tt) => {
				return [Math.min(memo[0], tt.minPrice.value), Math.max(memo[1], tt.maxPrice.value)];
			},
			[Infinity, -Infinity] as [number, number],
		);

		if (min !== max) {
			return dollarFormatter().formatRange(min, max);
		}
		return dollarFormatter().format(min);
	});

	return feeString();
}
