import { Title } from '@solidjs/meta';
import dayjs from 'dayjs';
import { useSearchParams } from '@solidjs/router';
import { createStore, produce } from 'solid-js/store';
import { createEffect, createResource, For, Match, Show, Suspense, Switch } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { ActivityIndicator, PillButton } from '@troon/ui';
import { IconCloseCircle } from '@troon/icons';
import { useWindowScrollPosition } from '@solid-primitives/scroll';
import { twJoin } from '@troon/tailwind-preset/merge';
import { TeeTimeSearchFields } from '../../components/tee-time-search';
import { Content } from '../../components/content';
import { gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import { FacilityCtx } from '../../providers/facility';
import { dayToDayJs } from '../../modules/date-formatting';
import { useUser } from '../../providers/user';
import { TeeTimesFacility } from './components/tee-times-facility';
import { EmptyState } from './components/empty-state';
import { NoFacilities, NoTeeTimes } from './components/no-results-state';
import { ViewBar } from './components/view-bar';
import { TeeTime } from './components/tee-time';
import { TeeTimeMini } from './components/tee-time-tiny';
import { FacilityFilterPill } from './components/facility-filter';
import type { Resource } from 'solid-js';
import type { ViewStyle } from './components/view-bar';
import type { CalendarDay, Facility, TeeTimeFacilitySearchQuery, TeeTimeFragment } from '../../graphql';
import type { Params } from '@solidjs/router';

type Store = {
	players: number | undefined;
	startAt: number;
	endAt: number;
	date: string;
	lat: number | undefined;
	lon: number | undefined;
	query: string;
	view: ViewStyle;
	useCard?: boolean;
	facilities?: string;
};

function queryParamStore<T extends Record<string, unknown>>(params: Partial<Params>, store: T): T {
	const out = { ...store };
	for (const [key, val] of Object.entries(out)) {
		if (!(key === 'players' || key === 'lat' || key === 'lon') && typeof params[key] === 'undefined') {
			continue;
		}
		const pVal = params[key] as string;
		if (typeof pVal === 'undefined') {
			continue;
		}
		if (Array.isArray(val)) {
			// @ts-expect-error TS is weird about the generic here
			out[key] = pVal.split(',');
		} else if (typeof val === 'boolean') {
			// @ts-expect-error TS is weird about the generic here
			out[key] = !!JSON.parse(pVal);
		} else if (key === 'players' || typeof val === 'number') {
			// @ts-expect-error TS is weird about the generic here
			out[key] = parseInt(pVal, 10);
		} else if (key === 'lat' || key === 'lon' || typeof val === 'number') {
			// @ts-expect-error TS is weird about the generic here
			out[key] = parseFloat(pVal);
		} else {
			// @ts-expect-error TS is weird about the generic here
			out[key] = pVal;
		}
	}
	return out;
}

export default function TeeTimeSearchPage() {
	const today = dayjs();
	const user = useUser();
	const initialDay = today.hour() < 16 ? today : today.add(1, 'day');
	const scroll = useWindowScrollPosition();

	const [params, setParams] = useSearchParams();
	const [filters, setFilters] = createStore<Store>(
		queryParamStore<Store>(params, {
			players: 2,
			startAt: 0,
			endAt: 24,
			date: initialDay.format('YYYY-MM-DD'),
			lat: undefined,
			lon: undefined,
			query: '',
			view: 'course',
			useCard: !!user()?.me.card,
		}),
	);

	createEffect(() => {
		setParams({ ...filters }, { replace: true });
	});

	const [facilities] = createResource(
		() => ({
			latitude: filters.lat,
			longitude: filters.lon,
			radius: 50,
			cardGroupId: filters.useCard ? user()?.me.card?.id : undefined,
			slugs: (filters.facilities ?? '').split(',').filter(Boolean),
		}),
		async (data) => {
			const { slugs, ...query } = data;
			if (!query.latitude || !query.longitude) {
				return { allFacilities: [], facilities: [] };
			}
			const res = await getFacilities(query as Parameters<typeof getFacilities>[0]);
			const allFacilities = res?.nearbyFacilities?.facilities ?? [];

			const facilities =
				slugs.length === 0 || slugs.length === allFacilities?.length
					? allFacilities
					: allFacilities?.filter((f) => slugs.includes(f.facility.slug));

			return { all: allFacilities, facilities };
		},
		{ deferStream: true },
	);

	const [maxDate] = createResource(() => {
		const maxDates = facilities.latest?.facilities
			?.flatMap((f) =>
				f.facility.courses.map((c) => dayToDayJs(c.bookingWindowDay as CalendarDay, f.facility.timezone).valueOf()),
			)
			.filter(Boolean);

		return maxDates ? new Date(Math.max(...maxDates)) : undefined;
	});

	return (
		<>
			<Title>Search for tee times | Troon Rewards</Title>
			<h1 class="sr-only">Search for tee times</h1>

			<div
				class={twJoin(
					'z-30 -mt-12 flex flex-col gap-6 bg-white py-6 transition-all md:sticky md:top-16 md:gap-6',
					scroll.y > 0 && 'md:shadow-md',
				)}
			>
				<Content>
					<div class="flex flex-row flex-wrap items-stretch xl:flex-nowrap">
						<TeeTimeSearchFields
							noButton
							card={filters.useCard ? user()?.me.card?.id : undefined}
							query={filters.query}
							onSelectLocation={(items) => {
								setFilters(
									produce((s) => {
										s.lat = items?.latitude;
										s.lon = items?.longitude;
										s.query = items?.place && items?.region ? [items.place, items.region].join(', ') : '';
									}),
								);
							}}
							onSelectDate={(date) => setFilters('date', dayjs(date).format('YYYY-MM-DD'))}
							onSelectTimeRange={(startAt, endAt) => {
								setFilters({ startAt, endAt });
							}}
							onSelectPlayers={(count) => {
								setFilters({ players: count });
							}}
							startAt={filters.startAt}
							endAt={filters.endAt}
							date={dayjs(filters.date).toDate()}
							maxDate={maxDate.latest}
							players={filters.players}
						/>
					</div>
				</Content>
			</div>

			<Content>
				<div class="mb-4 flex flex-row items-center justify-start gap-4">
					<Show when={user()?.me.card}>
						{(card) => (
							<PillButton
								aria-pressed={filters.useCard}
								onClick={() => {
									setFilters(
										produce((s) => {
											s.useCard = !s.useCard;
										}),
									);
								}}
							>
								{card().name}
								<Show when={filters.useCard}>
									<IconCloseCircle />
								</Show>
							</PillButton>
						)}
					</Show>
					<Suspense>
						<Show when={(facilities.latest?.all?.length ?? 0) > 1}>
							<FacilityFilterPill
								facilities={facilities()?.all as FacilitiesProp}
								selected={facilities()?.facilities?.map((f) => f.facility.slug)}
								onSelect={(items) => {
									if (items.length === facilities.latest?.all?.length) {
										setFilters('facilities', '');
										return;
									}
									if (items.length === 0) {
										setFilters('facilities', '_');
										return;
									}
									setFilters('facilities', items.map((i) => i).join(','));
								}}
							/>
						</Show>
					</Suspense>
				</div>

				<ViewBar selected={filters.view} onSelect={(style) => setFilters('view', style)} />

				<Show
					when={filters.lat && filters.lon}
					fallback={
						<EmptyState
							onSelectLocation={(loc) => {
								setFilters({ query: loc.displayValue, lat: loc.latitude, lon: loc.longitude });
							}}
						/>
					}
				>
					<Suspense fallback={<ActivityIndicator />}>
						<Internal
							facilities={facilities()?.facilities as FacilitiesProp}
							players={filters.players}
							startAt={filters.startAt}
							endAt={filters.endAt}
							date={filters.date}
							view={filters.view}
						/>
					</Suspense>
				</Show>
			</Content>
		</>
	);
}

type FacilitiesProp =
	| undefined
	| (TeeTimeFacilitySearchQuery['nearbyFacilities']['facilities'] & {
			facility: TeeTimeFacilitySearchQuery['nearbyFacilities']['facilities'][number];
	  });

type InternalProps = {
	facilities?: FacilitiesProp;
	players: number | undefined;
	date: string;
	startAt: number;
	endAt: number;
	view: ViewStyle;
};

function Internal(props: InternalProps) {
	const trackEvent = useTrackEvent();

	const [teeTimes] = createResource(
		() => {
			const courseIds = props.facilities?.flatMap((f) => f.facility.courses.map((c) => c.id));
			if (!courseIds?.length) {
				return { view: props.view };
			}

			const day = dayjs(props.date);

			return {
				facilities: props.facilities,
				courseIds,
				players: props.players,
				startAt: props.startAt,
				endAt: props.endAt,
				year: day.year(),
				month: day.month() + 1,
				day: day.date(),
				view: props.view,
			};
		},
		async (props) => {
			if (!props.facilities) {
				return props.view === 'course' ? {} : [];
			}

			const { facilities, ...filters } = props;
			const { view, ...query } = filters;

			trackEvent('getTeeTimesRequest', filters);
			const teeTimesRes = await getTeeTimes(query);
			if (teeTimesRes) {
				trackEvent('getTeeTimesSuccess', { ...filters, resultsCount: teeTimesRes?.teeTimes.length });
			} else {
				trackEvent('getTeeTimesFailure', filters);
			}

			const res = teeTimesRes?.teeTimes ?? [];

			if (filters.view === 'course') {
				return facilities?.length
					? facilities.reduce(
							(memo, facility) => {
								facility.facility.courses.forEach((course) => {
									memo[course.id] = ((res as Array<TeeTimeFragment>) ?? []).filter((tt) => tt.courseId === course.id);
								});
								return memo;
							},
							{} as Record<string, Array<TeeTimeFragment>>,
						)
					: {};
			}

			return res;
		},
		{ deferStream: false },
	);

	return (
		<Switch>
			<Match when={props.view === 'course'}>
				<Suspense>
					<Show when={teeTimes.state === 'refreshing' || teeTimes.state === 'ready' || teeTimes.state === 'errored'}>
						<div class="flex flex-col gap-6">
							<For each={props.facilities} fallback={<NoFacilities />}>
								{(facility) => (
									<FacilityCtx.Provider
										value={() => ({
											facility: facility.facility as Facility,
										})}
									>
										<TeeTimesFacility
											facility={facility.facility}
											distance={facility.distanceInMiles}
											teeTimes={teeTimes as Resource<Record<string, Array<TeeTimeFragment>>>}
											players={props.players}
											startAt={props.startAt}
											endAt={props.endAt}
											date={props.date}
										/>
									</FacilityCtx.Provider>
								)}
							</For>
						</div>
					</Show>
				</Suspense>
			</Match>
			<Match when={props.view === 'time'}>
				<Suspense fallback={<ActivityIndicator />}>
					<ol class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-6">
						<For
							each={teeTimes() as Array<TeeTimeFragment>}
							fallback={
								<div class="col-span-1 sm:col-span-2 lg:col-span-3">
									<NoTeeTimes />
								</div>
							}
						>
							{(teeTime) => (
								<Show
									when={
										props.facilities?.find((facility) =>
											facility.facility.courses.some(({ id }) => id === teeTime.courseId),
										)?.facility
									}
								>
									{(facility) => (
										<li>
											<TeeTime teeTime={teeTime} facility={facility()} selectedPlayers={props.players} />
										</li>
									)}
								</Show>
							)}
						</For>
					</ol>
				</Suspense>
			</Match>
			<Match when={props.view === 'grid'}>
				<Suspense fallback={<ActivityIndicator />}>
					<ol class="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4 lg:gap-6 xl:grid-cols-6">
						<For
							each={teeTimes() as Array<TeeTimeFragment>}
							fallback={
								<div class="col-span-2 sm:col-span-3 lg:col-span-4 xl:col-span-6">
									<NoTeeTimes />
								</div>
							}
						>
							{(teeTime) => (
								<Show
									when={
										props.facilities?.find((facility) =>
											facility.facility.courses.some(({ id }) => id === teeTime.courseId),
										)?.facility
									}
								>
									{(facility) => (
										<li>
											<TeeTimeMini teeTime={teeTime} facility={facility()} selectedPlayers={props.players} />
										</li>
									)}
								</Show>
							)}
						</For>
					</ol>
				</Suspense>
			</Match>
		</Switch>
	);
}

const facilityQuery = gql(`
query teeTimeFacilitySearch($latitude: Float!, $longitude: Float!, $radius: Int, $cardGroupId: String) {
	nearbyFacilities(latitude: $latitude, longitude: $longitude, radiusInMiles: $radius, cardGroupId: $cardGroupId) {
		facilities {
			distanceInMiles
			facility {
				name
				slug
				timezone
				metadata {
					hero {
						url
					}
					address {
						city
						state
					}
				}
				courses {
					id
					name
					bookingWindowDay {
						...Day
					}
				}
			}
		}
	}
}
`);

const getFacilities = cachedQuery(facilityQuery);

const teeTimesQuery = gql(`
	query teeTimes(
		$courseIds: [String!]!,
		$year: Int!,
		$month: Int!,
		$day: Int!,
		$startAt: Int!,
		$endAt: Int!,
		$includeCart: Boolean,
		$players: Int
	) {
		teeTimes: courseTeeTimes(
			courseIds: $courseIds,
			day: { year: $year, month: $month, day: $day },
			filters: {
				startAt: { hour: $startAt, minute: 0 },
				endAt: { hour: $endAt, minute: 0 },
				players: $players,
				includeCart: $includeCart,
			}
		) {
			...TeeTime
		}
	}`);

const getTeeTimes = cachedQuery(teeTimesQuery);
