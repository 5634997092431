import { createMemo, createSignal, Show } from 'solid-js';
import { ActivityIndicator, Link, Picture } from '@troon/ui';
import { useTrackEvent } from '@troon/analytics';
import { useIsRouting } from '@solidjs/router';
import { IconFlag, IconGolfCart, IconUsers } from '@troon/icons';
import { dayTimeToDate, formatDateTimeAttribute } from '../../../modules/date-formatting';
import { createDollarFormatter, createNumberFormatter, holesFormatter } from '../../../modules/number-formatting';
import type { TeeTimeFacilityFragment, TeeTimeFragment } from '../../../graphql';

type Props = {
	teeTime: TeeTimeFragment;
	facility: TeeTimeFacilityFragment;
	selectedPlayers?: number;
};
export function TeeTime(props: Props) {
	const dollarFormatter = createDollarFormatter('en', props.teeTime.minPrice.code ?? 'USD');
	const numberFormatter = createNumberFormatter();

	const feeString = createMemo(() => {
		const min = props.teeTime.minPrice;
		const max = props.teeTime.maxPrice;

		if (min.value !== max.value) {
			return dollarFormatter().formatRange(min.value, max.value);
		}
		return dollarFormatter().format(min.value);
	});

	const trackEvent = useTrackEvent();
	const [clicked, setClicked] = createSignal(false);
	const isRouting = useIsRouting();

	const params = createMemo(() => {
		const params = new URLSearchParams();
		if (props.selectedPlayers) {
			params.set('players', `${props.selectedPlayers}`);
		}
		return params;
	});

	return (
		<div class="relative flex flex-col gap-2 rounded border border-neutral p-4 hover:border-brand-300 hover:bg-brand-100">
			<Picture src={props.facility.metadata?.hero?.url} sizes={[[378, 118]]} class="w-full rounded" />
			<div class="flex items-start justify-between">
				<h4 class="text-nowrap text-xl font-semibold">
					<time datetime={formatDateTimeAttribute(dayTimeToDate(props.teeTime.dayTime))}>
						{dayTimeFormatter.format(dayTimeToDate(props.teeTime.dayTime))}
					</time>
				</h4>
				<div class="text-nowrap text-lg font-semibold">{feeString()}</div>
			</div>
			<div class="grow text-sm text-neutral-700">{props.facility.name}</div>
			<ul class="flex flex-row flex-wrap gap-4 text-sm">
				<li class="inline-flex items-center gap-1">
					<IconUsers />
					{props.teeTime.minPlayers !== props.teeTime.maxPlayers
						? numberFormatter().formatRange(props.teeTime.minPlayers, props.teeTime.maxPlayers)
						: numberFormatter().format(props.teeTime.minPlayers)}
					<span class="sr-only">golfer(s)</span>
				</li>
				<li class="inline-flex items-center gap-x-1">
					<IconFlag />
					<span>{holesFormatter(numberFormatter(), props.teeTime.holesOption)} holes</span>
				</li>
				{props.teeTime.cartIncluded ? (
					<li class="inline-flex items-center gap-x-1">
						<IconGolfCart title="Cart" /> Included
					</li>
				) : null}
			</ul>
			<Link
				href={`/course/${props.facility.slug}/reserve-tee-time/${props.teeTime.id}?${params().toString()}`}
				class="absolute inset-0 z-10"
				onClick={() => {
					setClicked(true);
					trackEvent('didSelectTeeTime', {
						courseId: props.teeTime.courseId,
						...props.teeTime.dayTime.day,
						...props.teeTime.dayTime.time,
					});
				}}
			>
				<span class="sr-only">
					Book {props.facility.name} at {dayTimeFormatter.format(dayTimeToDate(props.teeTime.dayTime))} for{' '}
					{feeString()}.
				</span>
			</Link>
			<Show when={clicked() && isRouting()}>
				<div class="absolute inset-0 z-20 flex items-center justify-center bg-white/50">
					<ActivityIndicator class="text-brand-600" />
				</div>
			</Show>
		</div>
	);
}

const dayTimeFormatter = new Intl.DateTimeFormat('en', {
	hour: 'numeric',
	minute: '2-digit',
	hour12: true,
});
