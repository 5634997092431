import { useIsRouting } from '@solidjs/router';
import { useTrackEvent } from '@troon/analytics';
import { IconUsers } from '@troon/icons';
import { Link, ActivityIndicator } from '@troon/ui';
import { createMemo, createSignal, Show } from 'solid-js';
import { formatDateTimeAttribute, dayTimeToDate } from '../../../modules/date-formatting';
import { createDollarFormatter } from '../../../modules/number-formatting';
import type { TeeTimeFragment, Currency } from '../../../graphql';

const dayTimeFormatter = new Intl.DateTimeFormat('en', {
	hour: 'numeric',
	minute: '2-digit',
	hour12: true,
});
type TeeTimeProps = {
	numberFormatter: Intl.NumberFormat;
	teeTime: TeeTimeFragment;
	facilitySlug: string;
	selectedPlayers?: number;
};
export function FacilityTeeTime(props: TeeTimeProps) {
	const dollarFormatter = createDollarFormatter('en', (props.teeTime.minPrice as Currency).code ?? 'USD');
	const feeString = createMemo(() => {
		const min = props.teeTime.minPrice;
		const max = props.teeTime.maxPrice;

		if (min.value !== max.value) {
			return dollarFormatter().formatRange(min.value, max.value);
		}
		return dollarFormatter().format(min.value);
	});

	const trackEvent = useTrackEvent();
	const [clicked, setClicked] = createSignal(false);
	const isRouting = useIsRouting();

	const params = createMemo(() => {
		const params = new URLSearchParams();
		if (props.selectedPlayers) {
			params.set('players', `${props.selectedPlayers}`);
		}
		return params;
	});

	return (
		<div class="relative">
			<Link
				preload={false}
				href={`/course/${props.facilitySlug}/reserve-tee-time/${props.teeTime.id}?${params().toString()}`}
				class="group block rounded border border-brand-100 focus-visible:ring-4 focus-visible:ring-brand-500/50 focus-visible:ring-offset-2"
				rel="nofollow"
				onClick={() => {
					setClicked(true);
					trackEvent('didSelectTeeTime', {
						courseId: props.teeTime.courseId,
						...props.teeTime.dayTime.day,
						...props.teeTime.dayTime.time,
					});
				}}
			>
				<h4 class="text-nowrap rounded-t-lg bg-brand-100 px-6 py-1 font-semibold text-brand-600 group-hover:bg-brand-200">
					<time datetime={formatDateTimeAttribute(dayTimeToDate(props.teeTime.dayTime))}>
						{dayTimeFormatter.format(dayTimeToDate(props.teeTime.dayTime))}
					</time>
				</h4>
				<ul class="flex flex-col gap-1 py-1">
					<li class="flex flex-row items-center justify-center gap-1 text-sm">
						<IconUsers />
						{props.teeTime.minPlayers !== props.teeTime.maxPlayers
							? props.numberFormatter.formatRange(props.teeTime.minPlayers, props.teeTime.maxPlayers)
							: props.numberFormatter.format(props.teeTime.minPlayers)}
						<span class="sr-only">players</span>
					</li>
					<li class="text-nowrap text-center text-sm">{feeString()}</li>
				</ul>
			</Link>
			<Show when={clicked() && isRouting()}>
				<div class="absolute inset-0 flex items-center justify-center bg-white/50">
					<ActivityIndicator class="text-brand-600" />
				</div>
			</Show>
		</div>
	);
}

export function FacilityTeeTimeSkeleton() {
	return (
		<div class="relative">
			<div class="animate-pulse rounded border border-brand-100">
				<div class="relative flex min-w-24 items-center justify-center rounded-t-lg bg-brand-100 px-6 py-1 font-semibold text-brand-600">
					<div class="absolute h-2 w-12 animate-pulse rounded-full bg-brand-300" />
					&nbsp;
				</div>
				<ul class="flex flex-col gap-1 py-1 text-sm">
					<li class="relative flex items-center justify-center">
						<div class="absolute h-2 w-10 animate-pulse rounded-full bg-neutral-300" />
						&nbsp;
					</li>
					<li class="relative flex items-center justify-center">
						<div class="absolute h-2 w-6 animate-pulse rounded-full bg-neutral-300" />
						&nbsp;
					</li>
				</ul>
			</div>
		</div>
	);
}
