import { createMemo, For, Match, Show, Suspense, Switch } from 'solid-js';
import { ActivityIndicator, Picture, TextLink } from '@troon/ui';
import { twJoin } from '@troon/tailwind-preset/merge';
import dayjs from 'dayjs';
import { createNumberFormatter } from '../../../modules/number-formatting';
import { dayToDayJs } from '../../../modules/date-formatting';
import { NoTeeTimes } from './no-tee-times-bar';
import { SetAlert } from './set-alert';
import { PriceRange } from './price-range';
import { ShowAll } from './show-all';
import { FacilityTeeTime, FacilityTeeTimeSkeleton } from './facility-tee-time';
import { OutsideBookingWindow } from './outside-booking-window';
import type { Resource } from 'solid-js';
import type { CalendarDay, TeeTimeFacilityFragment, TeeTimeFragment } from '../../../graphql';

type Props = {
	facility: TeeTimeFacilityFragment;
	distance: number;
	teeTimes: Resource<Record<string, Array<TeeTimeFragment>>>;
	players: number | undefined;
	date: string;
	startAt: number;
	endAt: number;
};

export const numTeeTimesToShow = 6;

export function TeeTimesFacility(props: Props) {
	const numberFormatter = createNumberFormatter();

	const maxBookingDate = createMemo(() =>
		props.facility.courses.reduce(
			(memo, c) =>
				dayToDayJs(c.bookingWindowDay as CalendarDay, props.facility.timezone).isAfter(memo)
					? dayToDayJs(c.bookingWindowDay as CalendarDay, props.facility.timezone)
					: memo,
			dayjs.tz(new Date(), props.facility.timezone),
		),
	);

	return (
		// hack fix for Safari. grid needs to be in a grid otherwise child grid's rows get too tall
		<div class="grid gap-6">
			<div class="grid grid-cols-3 gap-4 border-b border-neutral pb-6 sm:grid-cols-4 md:pb-8 lg:grid-cols-9 lg:gap-6">
				<div class="col-span-1 lg:col-span-2 lg:row-span-2">
					<Picture
						src={props.facility.metadata?.hero?.url}
						alt=""
						loading="lazy"
						sizes={[
							[400, 300],
							[90, 90],
						]}
						// eslint-disable-next-line tailwindcss/no-arbitrary-value
						class={twJoin(
							'aspect-square w-full max-w-64 rounded md:max-w-none lg:aspect-[4/3]',
							maxBookingDate().isBefore(props.date) && 'opacity-60',
						)}
					/>
				</div>
				<div class="col-span-2 flex h-full flex-col justify-start gap-1 sm:col-span-3 lg:col-span-7">
					<h2 class="text-base font-semibold sm:text-xl">
						<TextLink href={`/course/${props.facility.slug}`} class="text-black">
							{props.facility.name}
						</TextLink>
					</h2>
					<p class="text-sm text-neutral-800">
						{props.facility.metadata?.address?.city}, {props.facility.metadata?.address?.state} •{' '}
						{distanceFormatter.format(props.distance)}
					</p>
					<p class="text-sm text-neutral-800">
						<Suspense>
							<PriceRange teeTimes={props.teeTimes()} />
						</Suspense>
					</p>
				</div>

				<div class="relative col-span-3 flex flex-col items-start justify-start gap-2 overflow-hidden sm:col-span-4 lg:col-span-7 lg:col-start-3">
					<Switch>
						<Match when={maxBookingDate().isBefore(props.date)}>
							<OutsideBookingWindow />
						</Match>
						<Match when={!props.teeTimes.loading && Object.values(props.teeTimes.latest ?? {}).flat().length === 0}>
							<NoTeeTimes players={props.players} startAt={props.startAt} endAt={props.endAt} date={props.date} />
						</Match>
						<Match when>
							<For each={props.facility.courses}>
								{(course) => (
									<Show when={props.teeTimes.loading || Object.values(props.teeTimes.latest ?? {}).flat().length}>
										<Show when={props.facility.courses.length > 1}>
											<h3 class="text-sm font-semibold">{course.name}</h3>
										</Show>

										<Suspense
											fallback={
												<ul class="flex flex-row gap-4 pb-2">
													<For each={new Array(Math.ceil(Math.random() * 5)).fill(null)}>
														{() => (
															<li>
																<FacilityTeeTimeSkeleton />
															</li>
														)}
													</For>
												</ul>
											}
										>
											<div
												class={twJoin(
													'w-full',
													props.teeTimes() && props.teeTimes()![course.id]?.length && 'snap-x overflow-auto',
												)}
											>
												<ul
													class={twJoin(
														'flex flex-row gap-2 pb-2',
														props.teeTimes() && props.teeTimes()![course.id]?.length && 'min-w-max',
													)}
												>
													<Suspense
														fallback={
															<li>
																<ActivityIndicator />
															</li>
														}
													>
														<For
															each={props.teeTimes()![course.id]?.slice(0, numTeeTimesToShow)}
															fallback={
																<li>
																	<NoTeeTimes
																		players={props.players}
																		startAt={props.startAt}
																		endAt={props.endAt}
																		date={props.date}
																	/>
																</li>
															}
														>
															{(teeTime) => (
																<li class="snap-start">
																	<FacilityTeeTime
																		selectedPlayers={props.players}
																		numberFormatter={numberFormatter()}
																		teeTime={teeTime}
																		facilitySlug={props.facility.slug}
																	/>
																</li>
															)}
														</For>
														<Show when={(props.teeTimes()![course.id]?.length ?? 0) > numTeeTimesToShow}>
															<li class="me-8 snap-end">
																<ShowAll
																	facilitySlug={props.facility.slug}
																	name={`${props.facility.name} ${course.name}`}
																/>
															</li>
														</Show>
														<Show
															when={
																(props.teeTimes()![course.id]?.length ?? 0) > 0 &&
																(props.teeTimes()![course.id]?.length ?? 0) < numTeeTimesToShow
															}
														>
															<li>
																<SetAlert
																	players={props.players}
																	startAt={props.startAt}
																	endAt={props.endAt}
																	date={props.date}
																/>
															</li>
														</Show>
													</Suspense>
												</ul>
											</div>
										</Suspense>
									</Show>
								)}
							</For>
						</Match>
					</Switch>
				</div>
			</div>
		</div>
	);
}

const distanceFormatter = new Intl.NumberFormat('en-US', {
	unit: 'mile',
	maximumFractionDigits: 1,
	unitDisplay: 'short',
	style: 'unit',
});
